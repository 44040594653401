import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PatternContainer from "../components/pattern-container";

// TODO - this
function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <PatternContainer className="py-32">
        <h1>404</h1>
        <pre className="text-center mt-8">¯\_(o)_/¯ (it's a bagel)</pre>
        <p className="text-center mt-8">
          Whatever it is, it ain't here. Dry your tears with a delicious bagel.
        </p>
      </PatternContainer>
    </Layout>
  );
}

export default NotFoundPage;
